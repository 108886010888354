@font-face {
  font-family: 'FontiBlack';
  src: url('/public/font/Spartan-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiBold';
  src: url('/public/font/Spartan-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiEBold';
  src: url('/public/font/Spartan-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiELight';
  src: url('/public/font/Spartan-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiLight';
  src: url('/public/font/Spartan-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiMedium';
  src: url('/public/font/Spartan-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiRegular';
  src: url('/public/font/Spartan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiSemibold';
  src: url('/public/font/Spartan-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FontiThin';
  src: url('/public/font/Spartan-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Font-black {
  font-family: 'FontiBlack', sans-serif;
}

.Font-EBold {
  font-family: 'FontiEBold', sans-serif;
}

.Font-ELight {
  font-family: 'FontiELight', sans-serif;
}

.Font-Light {
  font-family: 'FontiLight', sans-serif;
}

.Fonti-Medium {
  font-family: 'FontiMedium', sans-serif;
}

.Fonti-Regular {
  font-family: 'FontiRegular', sans-serif;
}

.Fonti-Semibold {
  font-family: 'FontiSemibold', sans-serif;
}

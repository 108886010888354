/* MainRow.css */
.section img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 20px;
}

@media screen and (max-width: 600px) {
    .section {
        flex-wrap: wrap;
    }

    .section img {
        width: 100px;
        height: 100px;
    }
}

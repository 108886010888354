.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 130px;
    z-index: 1;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* transition-timing-function: ease-in;
    transition: all 0.5s; */
  }
  
  .nav__black {
      /* background-color: #111; */
  }
  
  .nav__logo {
      position: fixed;
      left: 40px;
      width: 80px;
      object-fit: contain;
  }
  
  /* .nav__avatar {
      position: fixed;
      right: 40px;
      width: 30px;
      object-fit: contain;
  }
  
  .nav__input {
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: rgba(0, 0, 0, 0.582);
      border-radius: 5px;
      color: white;
      padding: 5px;
      border: none;
  } */